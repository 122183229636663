import Requisito from "./Requisito";
import { Button } from "@mui/material";
const RequisitosContainer = ({data}) => {
  return (
    <section className='requisitos-contenedor'>
      <div className='requisitos-columna'>
        <div className='titulo-columna'>
          <h2>
            Requisitos para la <span>Pre-Autorización:</span>
          </h2>
        </div>
        {
        data.requisitos.map((requisito,i) => {
          
          if(requisito.preautorizacion){
            return(<Requisito requisito={requisito} numero={i}  />)
          }
        })}
        
        <p className="disclaimer">Una vez aprobada la pre autorización, se pedirá la documentación restante descrita en la solicitud del programa. Y se tendrán 10 días hábiles para completar la documentación</p>
        
      </div>
      <div className='requisitos-columna'>
        <div className='titulo-columna'>
          <h2>
            Requisitos restantes una vez <span>aprobada la pre-autorización:</span>
          </h2>
        </div>
        {data.requisitos.map((requisito,i) => {
          if(!requisito.preautorizacion){
            return(<Requisito requisito={requisito} numero={i} />)
          }
        })}
       
        
    
      </div>
    </section>
  );
};

export default RequisitosContainer;
