import Sucursal from "./Sucursal";
import Data from "../../DataStructure.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ModalAvisoPrivacidad from "./ModalAvisoPrivacidad";
const TopFooter = () => {
  const [modal, setModal] = useState(false);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <footer className="top-footer">
      <div className="logo-container">
        <img src="https://s3.us-east-2.amazonaws.com/fideapech.com/Assets/Logo/LogoNov2024.svg" alt="gob" />
      </div>
      {Data.sucursales.map((sucursal) => (
        <Sucursal key={sucursal.id} data={sucursal} />
      ))}

      <div className="about">
        {modal ? <ModalAvisoPrivacidad setModal={setModal} /> : null}
        <h3>Nosotros</h3>
        <div className="options">
          <h4 onClick={() => navigate("/Quienes-Somos")}>Conócenos</h4>

          <h4 onClick={() => navigate("/FAQ")}>Preguntas Frecuentes</h4>

          <h4
            onClick={() =>
              window.open("http://chihuahua.com.mx/etica.php", "_blank")
            }
          >
            Código de Ética
          </h4>
          <h4
            onClick={() =>
              window.open("https://ichitaip.org/redireccion/?so=1606", "_blank")
            }
          >
            {" "}
            Transparencia
          </h4>
          <h4 onClick={() => setModal(true)}>Aviso de Privacidad</h4>
          <h4 onClick={() => navigate("/TransparenciaProactiva")}>Transparencia Proactiva</h4>
          <h4 onClick={() => window.open("https://www.fideapech.com/transparenciaDocs/Reglas-Operacion-Fideapech.pdf")}>Reglas de Operación</h4>
        </div>
      </div>
    </footer>
  );
};

export default TopFooter;
